import { node, shape } from "prop-types"
import React, { useState } from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import styled from "styled-components"

import skin, { color } from "../components/_globalSettings/skin"
import { typography } from "../components/_globalSettings/typography"
import { breakpoint } from "../components/_globalSettings/var"
import ContentList from "../components/ContentList"
import ModalForm from "../components/Form/ModalForm"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import Button from "../components/Link/Button"
import TabData from "../components/TabData"
import Cross from "../images/icons/cross.svg"
import HalfHamburger from "../images/icons/half-hamburger.svg"
import useGlobal from "../store"
import formatFormData from "../utils/formFormatDocumentation"

const StyledCardBlock = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 80px;
	flex-direction: column;
	align-items: flex-start;
	.card-block__image {
		padding: 0 55px 25px 0;
	}
	h3 {
		font-size: 25px;
		line-height: 31px;
		padding-bottom: 8px;
	}
	h4 {
		padding-bottom: 16px;
	}
	p {
		${typography.bodyXL};
		color: ${color.main.text1};
	}
	ul {
		list-style-type: none;
		padding-left: 34px;
		padding-top: 40px;
		li {
			position: relative;
			padding-bottom: 40px;
			&:before {
				content: "□";
				color: #2548ff;
				position: absolute;
				left: -32px;
				line-height: 30px;
				font-size: 17px;
			}
		}
		li:last-of-type {
			padding-bottom: 0;
		}
		strong {
			font-size: 21px;
			line-height: 130%;
			color: ${color.main.text1};
		}
	}
`

const TabContent = styled.div`
	display: flex;
	flex-direction: column;
	h3 {
		margin-bottom: 24px;
	}
	p {
		${typography.BodyXL};
		color: ${color.main.text1};
	}
	.describe__img {
		margin-top: 50px;
	}
`

const StyledTabList = styled(TabList)`
	display: flex;
	flex-grow: 1;
	background: ${color.light.n600};
	border-radius: 4px;
`

const StyledTabPanel = styled(TabPanel)`
	@media (max-width: ${breakpoint.default.to}px) {
		margin-top: 50px;
	}
`

const StyledTabs = styled(Tabs)`
	margin-top: 16px;
	@media (max-width: ${breakpoint.default.to}px) {
		margin-top: 0;
	}
`

const StyledTab = styled(Tab)`
	background: grey;
	list-style-type: none;
	width: 50%;
	padding: 16px 0;
	border-radius: 2px;
	color: ${color.main.text3};
	${typography.AllCapsBoldS};
	background: ${color.light.n600};
	cursor: pointer;
	margin: 8px;
	text-align: center;
	&.react-tabs__tab--selected {
		color: ${color.main.primary1};
		background: #fff;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		font-size: 12px;
	}
`

const LeftNav = styled.div`
	min-width: 400px;
	margin-right: 80px;
	align-self: flex-start;
	position: sticky;
	top: 96px;
	max-height: calc(100vh - 96px);
	overflow-y: auto;
	padding-bottom: 8px;
	.card__link {
		width: 100%;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: calc(100% - 32px);
		background: #fff;
		padding-right: 0;
		position: static;
		min-width: 0;
		position: fixed;
		bottom: 0;
		top: auto;
		height: 80px;
		z-index: 1001;
		transition: 1s;
		&.open {
			height: 100%;
		}
	}
`

const DocumentationWrap = styled.div`
	display: flex;
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
	}
`

const BottomMenu = styled.div`
	width: 100%;
	display: flex;
	background: #fff;
	padding: 16px;
	.openMenu {
		all: unset;
		margin-right: 28px;
		cursor: pointer;
		width: 62px;
		text-align: center;
		background: #ffffff;
		box-shadow: 0px 20px 32px rgba(9, 20, 79, 0.05);
		border-radius: 4px;
		display: none;
	}
	a {
		height: 48px;
		line-height: 13px;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		position: fixed;
		left: 0;
		bottom: 0;
		.openMenu {
			display: block;
		}
	}
	@media (max-width: ${breakpoint.min.to}px) {
		a {
			font-size: 11px;
		}
	}
`

const TopLvlMenu = styled.div`
	display: flex;
	button {
		all: unset;
	}
	.cross {
		display: none;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		padding-top: 12px;
		.cross {
			display: block;
			margin-left: 22px;
			margin-right: 6px;
			cursor: pointer;
		}
	}
`

const CardBlock = ({ children }) => (
	<StyledCardBlock>{children}</StyledCardBlock>
)

CardBlock.propTypes = {
	children: node.isRequired,
}

const Documentation = ({ pageContext }) => {
	const agileHashes = []
	pageContext.agile.togglers.map(toggler => agileHashes.push(toggler.url))
	agileHashes.push("co-je-agile", "vyhody-agilu", "nevyhody-agilu")

	const isBrowser = typeof window !== "undefined"

	let compareHash = () => {}
	let setHash

	if (isBrowser) {
		setHash = hash => {
			window.location.hash = hash
			window.scroll(0, 0)
		}
		compareHash = () => {
			if (agileHashes.includes(window.location.hash.substr(1))) return true
			return false
		}
	}

	const [globalState, globalActions] = useGlobal()
	const [tabIndex, setTabIndex] = useState(compareHash() === true ? 1 : 0)
	const [openMenu, setOpenMenu] = useState(false)

	return (
		<Layout {...pageContext}>
			<StyledTabs
				selectedIndex={tabIndex}
				onSelect={index => setTabIndex(index)}
			>
				<Row>
					<DocumentationWrap>
						<LeftNav className={openMenu && `open`}>
							<TopLvlMenu>
								<StyledTabList>
									<StyledTab onClick={() => setHash("#co-je-waterfall")}>
										Waterfall
									</StyledTab>
									<StyledTab onClick={() => setHash("#co-je-agile")}>
										Agile
									</StyledTab>
								</StyledTabList>
								<button type="button" onClick={() => setOpenMenu(false)}>
									<img src={Cross} alt="Zavřít menu" className="cross" />
								</button>
							</TopLvlMenu>
							{tabIndex === 0 ? (
								<ContentList menuItems={pageContext.waterfall} />
							) : (
								<ContentList menuItems={pageContext.agile} />
							)}
							<BottomMenu>
								<button
									type="button"
									onClick={() => setOpenMenu(true)}
									className="openMenu"
								>
									<img src={HalfHamburger} alt="Otevřít menu" />
								</button>
								<Button
									onClick={() => {
										if (
											globalState.windowDimensions.width >=
											breakpoint.default.from
										) {
											globalActions.modalOpen(true)
										}
									}}
									href={
										globalState.windowDimensions.width <= breakpoint.default.to
											? "/tvorba-zadani-zadost"
											: ""
									}
									skin={skin.blue}
									className="card__link"
								>
									{pageContext.banner.button.label}
								</Button>
							</BottomMenu>
							<ModalForm
								headline={pageContext.helpForm.headline}
								data={pageContext.helpForm.form}
								id="helpFormDocumentation"
								formatFormData={formatFormData}
							/>
						</LeftNav>
						<StyledTabPanel>
							<TabContent>
								<TabData data={pageContext.waterfall} />
							</TabContent>
						</StyledTabPanel>
						<StyledTabPanel>
							<TabContent>
								<TabData data={pageContext.agile} />
							</TabContent>
						</StyledTabPanel>
					</DocumentationWrap>
				</Row>
			</StyledTabs>
		</Layout>
	)
}

Documentation.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default Documentation
