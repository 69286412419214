import { arrayOf, shape, string } from "prop-types"
import React from "react"

import key from "../../utils/key"
import { Heading3 } from "../_globalSettings/typography"
import Image from "../Image"
import StyledListSpecial from "./style"

const ListSpecial2 = ({ data, ...rest }) => {
	return (
		<StyledListSpecial>
			<div id={data.check.blocks[0].url} className="special-list__check">
				{data.check.headline && (
					<h2 className="special-list__headline check-list__headline">
						{data.check.headline}
					</h2>
				)}
				{data.check.blocks.map((block, ib) => (
					<div key={key(ib, block.headline)}>
						<Heading3>{block.headline}</Heading3>
						<ul className="special-list__ul" {...rest}>
							{block.points.map((point, i) => (
								<li
									className="check-list__li special-list__li"
									key={key(i, point)}
								>
									{point}
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
			<div id={data.uncheck.blocks[0].url} className="special-list__uncheck">
				{data.uncheck.headline && (
					<Heading3 className="special-list__headline uncheck-list__headline">
						{data.uncheck.headline}
					</Heading3>
				)}
				{data.uncheck.blocks.map((block, ib) => (
					<div key={key(ib, block.headline)}>
						<Heading3 className="special-list__subheadline">
							{block.headline}
						</Heading3>
						<ul className="special-list__ul" {...rest}>
							{block.points.map((point, i) => (
								<li
									className="uncheck-list__li special-list__li"
									key={key(i, point)}
								>
									{point}
								</li>
							))}
						</ul>
					</div>
				))}
				{data.uncheck.star && (
					<p className="special-list__star">{data.uncheck.star}</p>
				)}
				{data.image && <Image data={data.image} alt="" />}
			</div>
		</StyledListSpecial>
	)
}

export const ListSpecial2Props = {
	data: shape({
		check: shape({
			headline: string.isRequired,
			blocks: arrayOf(
				shape({
					headline: string.isRequired,
					points: arrayOf(string).isRequired,
					url: string.isRequired,
				})
			),
			star: string,
		}).isRequired,
		uncheck: shape({
			headline: string.isRequired,
			blocks: arrayOf(
				shape({
					headline: string.isRequired,
					points: arrayOf(string).isRequired,
					url: string.isRequired,
				})
			),
			star: string,
		}).isRequired,
	}),
}

ListSpecial2.propTypes = {
	...ListSpecial2Props,
}

export default ListSpecial2
