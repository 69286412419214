import { arrayOf, shape, string } from "prop-types"
import React from "react"
import Scrollspy from "react-scrollspy"

import StyledContentList, { StyledContentListList } from "./style"

const ContentList = ({ menuItems }) => {
	const hash = global.window && window.location.hash

	const allHashes = []

	allHashes.push(menuItems.describe.url)
	allHashes.push(menuItems.checkblock.check.blocks[0].url)
	allHashes.push(menuItems.checkblock.uncheck.blocks[0].url)
	allHashes.push("kroky-ke-spravnemu-zadani")

	menuItems.togglers.map(toggler => allHashes.push(toggler.url))

	return (
		<StyledContentList>
			<>
				<div className="title">Úvod</div>
				<StyledContentListList>
					<Scrollspy
						items={allHashes}
						currentClassName="is-current"
						offset={-200}
					>
						<li>
							<a
								href={`#${menuItems.describe.url}`}
								className={hash === `#${menuItems.describe.url}` && `active`}
							>
								{menuItems.describe.headline}
							</a>
						</li>
						<li>
							<a
								href={`#${menuItems.checkblock.check.blocks[0].url}`}
								className={
									hash === `#${menuItems.checkblock.check.blocks[0].url}` &&
									`active`
								}
							>
								{menuItems.checkblock.check.blocks[0].headline}
							</a>
						</li>
						<li>
							<a
								href={`#${menuItems.checkblock.uncheck.blocks[0].url}`}
								className={
									hash === `#${menuItems.checkblock.uncheck.blocks[0].url}` &&
									`active`
								}
							>
								{menuItems.checkblock.uncheck.blocks[0].headline}
							</a>
						</li>
						<div className="title">Kroky ke správnému zadání</div>
						{menuItems.togglers.map(toggler => (
							<li key={toggler.url}>
								<a
									href={`#${toggler.url}`}
									className={hash === `#${toggler.url}` && `active`}
								>
									{toggler.header}
								</a>
							</li>
						))}
					</Scrollspy>
				</StyledContentListList>
			</>
		</StyledContentList>
	)
}

ContentList.propTypes = {
	menuItems: shape({
		title: string.isRequired,
		headline: string.isRequired,
		describe: shape({
			headline: string.inRequired,
			content: string.isRequired,
			image: shape({
				publicURL: string.isRequired,
			}),
		}),
		checkblock: shape({
			check: shape({
				headline: string.isRequired,
				blocks: arrayOf(
					shape({
						headline: string.isRequired,
						points: arrayOf(string),
						url: string.isRequired,
					})
				),
			}),
			uncheck: shape({
				headline: string.isRequired,
				blocks: arrayOf(
					shape({
						headline: string.isRequired,
						points: arrayOf(string),
						url: string.isRequired,
					})
				),
			}),
		}),
		subheadline: string.isRequired,
		togglers: arrayOf({
			header: string.isRequired,
			warning: string,
			content: arrayOf(string),
			url: string.isRequired,
			cards: arrayOf(
				shape({
					headline: string.isRequired,
					content: string.isRequired,
					customMarkup: string,
					image: string,
					warning: string,
				})
			),
		}),
	}).isRequired,
}

export default ContentList
