import styled, { css } from "styled-components"

import { color } from "../_globalSettings/skin"
import { typography } from "../_globalSettings/typography"
import { breakpoint } from "../_globalSettings/var"

const StyledContentList = styled.div`
	padding: 36px 16px 0;
	.title {
		font-weight: 600;
		font-size: 12px;
		line-height: 110%;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: ${color.main.text3};
		padding-bottom: 8px;
		margin-bottom: 22px;
		border-bottom: 1px solid ${color.light.n500};
	}
	@media (max-width: ${breakpoint.default.to}px) {
		overflow-y: auto;
		height: calc(100% - 153px);
	}
`

const styledList = css`
	color: ${color.main.text1};
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 160%;
	position: relative;
	li {
		margin-bottom: 26px;
		list-style-type: none;
	}
	a {
		color: ${color.main.text1};
		text-decoration: none;
		${typography.SubtitleM};
		font-weight: 500;
	}
	a:hover,
	.is-current a {
		color: ${color.main.primary1};
	}
	.is-current a::before,
	a:hover::before {
		position: absolute;
		content: "";
		left: -16px;
		height: 24px;
		width: 4px;
		background-color: ${color.main.primary1};
	}
`

export const StyledContentListList = styled.ul`
	${styledList};
	list-style: none;
`

export const StyledContentListOrderList = styled.ol`
	${styledList};
	list-style: inside decimal;
`

export default StyledContentList
