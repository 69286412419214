import { array, arrayOf, node, shape, string } from "prop-types"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import ArrowRight from "../../images/icons/breadcrumb-arrow.svg"
import sanitized from "../../utils/sanitizeHTML"
import { color } from "../_globalSettings/skin"
import { Heading3, Heading4, typography } from "../_globalSettings/typography"
import { breakpoint } from "../_globalSettings/var"
import Image from "../Image"
import ListSpecial2 from "../ListSpecial2"

const Togglers = styled.div`
	padding-top: 100px;
	h3 {
		margin-bottom: 64px;
	}
	border-bottom: 1px solid ${color.light.n500};
	padding-bottom: 20px;
`

const BreadcrumbNav = styled.div`
	margin-bottom: 27px;
	font-weight: 500;
	${typography.LabelS};
	img {
		margin-left: 8px;
		margin-right: 14px;
	}
	.breadcrumb__first-lvl {
		color: ${color.main.text3};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.breadcrumb__second-lvl {
		color: ${color.main.text1};
	}
`

const WarningBox = styled.div`
	${props => props.marginTop && "margin-top: 100px"};
	box-shadow: 0px 20px 32px rgba(9, 20, 79, 0.05);
	padding: 32px;
	position: relative;
	background: #fff;
	${typography.BodyL};
	.warning-box__title {
		font-weight: 700;
		color: ${color.main.primary1};
	}
	&::after {
		content: "";
		position: absolute;
		top: 16px;
		left: 16px;
		border: 1px solid ${color.main.primary1};
		width: 100%;
		height: calc(100%);
		z-index: -1;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		&::after {
			display: none;
		}
	}
`

const ToggleContent = styled.div`
	${typography.BodyL};
	margin-top: 50px;
	color: ${color.main.text1};
	margin-bottom: 80px;
`

const StyledCardBlock = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 80px;
	flex-direction: column;
	align-items: flex-start;
	.card-block__image {
		padding: 0 55px 25px 0;
	}
	h3 {
		font-size: 25px;
		line-height: 31px;
		padding-bottom: 8px;
	}
	h4 {
		padding-bottom: 16px;
	}
	p {
		${typography.bodyXL};
		color: ${color.main.text1};
	}
	ul {
		list-style-type: none;
		padding-left: 34px;
		padding-top: 40px;
		li {
			position: relative;
			padding-bottom: 40px;
			&:before {
				content: "□";
				color: #2548ff;
				position: absolute;
				left: -32px;
				line-height: 30px;
				font-size: 17px;
			}
		}
		li:last-of-type {
			padding-bottom: 0;
		}
		strong {
			font-size: 21px;
			line-height: 130%;
			color: ${color.main.text1};
		}
	}
`

const FirstBlock = styled.div`
	position: relative;
	top: -80px;
	padding-top: 96px;
`

const CardBlock = ({ children }) => (
	<StyledCardBlock>{children}</StyledCardBlock>
)

CardBlock.propTypes = {
	children: node.isRequired,
}

const TabData = ({ data }) => {
	return (
		<>
			<div style={{ maxWidth: "840px" }}>
				<FirstBlock id={data.describe.url}>
					<BreadcrumbNav>
						<Link className="breadcrumb__first-lvl" to="/tvorba-zadani">
							Tvorba zadání
						</Link>
						<img src={ArrowRight} alt="Šipka" />
						<span className="breadcrumb__second-lvl">
							{data.title === "agile" ? "Agile" : "Waterfall"}
						</span>
					</BreadcrumbNav>
					{data.describe.headline && (
						<Heading3>{data.describe.headline}</Heading3>
					)}
					{data.describe.content && <p>{data.describe.content}</p>}
					{data.describe.image && (
						<Image
							data={data.describe.image}
							alt={data.describe.headline}
							className="describe__img"
						/>
					)}
				</FirstBlock>
			</div>
			<ListSpecial2 data={data.checkblock} style={{ paddingLeft: "40px" }} />
			<div
				id="kroky-ke-spravnemu-zadani"
				style={{ position: "relative", top: "-400px" }}
			/>
			{data.togglers.map(toggle => {
				return (
					<Togglers key={toggle.url} id={toggle.url}>
						<div />
						{toggle.header && <Heading3>{toggle.header}</Heading3>}
						{toggle.warning && (
							<WarningBox>
								<span className="warning-box__title">Důležité: </span>
								{toggle.warning}
							</WarningBox>
						)}
						{toggle.content &&
							toggle.content.map(toggleContent => {
								return (
									<ToggleContent>
										<p
											dangerouslySetInnerHTML={{
												// eslint-disable-line
												__html: sanitized(toggleContent),
											}}
										/>
									</ToggleContent>
								)
							})}
						{toggle.cards.map(card => (
							<CardBlock key={card.headline}>
								{card.image && (
									<Image
										data={card.image}
										alt={card.headline}
										className="card-block__image"
									/>
								)}
								<div className="card-block__body">
									{card.headline && <Heading4>{card.headline}</Heading4>}
									{card.content && <p>{card.content}</p>}
									{card.customMarkup && (
										<div
											dangerouslySetInnerHTML={{
												// eslint-disable-line
												__html: sanitized(card.customMarkup),
											}}
										/>
									)}
									{card.warning && (
										<WarningBox marginTop>
											<span className="warning-box__title">Důležité:</span>
											{card.warning}
										</WarningBox>
									)}
								</div>
							</CardBlock>
						))}
					</Togglers>
				)
			})}
		</>
	)
}

TabData.propTypes = {
	data: shape({
		title: string.isRequired,
		headline: string.isRequired,
		describe: shape({
			headline: string.inRequired,
			content: string.isRequired,
			image: shape({
				publicURL: string.isRequired,
			}).isRequired,
		}),
		checkblock: shape({
			check: shape({
				headline: string.isRequired,
				blocks: arrayOf(
					shape({
						headline: string.isRequired,
						points: arrayOf(string),
						url: string.isRequired,
					})
				),
			}),
			uncheck: shape({
				headline: string.isRequired,
				blocks: arrayOf(
					shape({
						headline: string.isRequired,
						points: arrayOf(string),
						url: string.isRequired,
					})
				),
			}),
		}),
		subheadline: string.isRequired,
		togglers: arrayOf({
			header: string.isRequired,
			warning: string,
			content: array,
			url: string.isRequired,
			cards: arrayOf(
				shape({
					headline: string.isRequired,
					content: string.isRequired,
					customMarkup: string,
					image: string,
					warning: string,
				})
			),
		}),
	}).isRequired,
}

export default TabData
