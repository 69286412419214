import styled from "styled-components"

import { color } from "../_globalSettings/skin"
import { typography } from "../_globalSettings/typography"
import { breakpoint } from "../_globalSettings/var"

const StyledListSpecial = styled.div`
	font-size: 18px;
	line-height: 28px;
	display: flex;
	flex-direction: column;
	padding-top: 40px;
	.special-list__headline {
		font-weight: 600;
		font-size: 31px;
		line-height: 38px;
		padding-bottom: 65px;
		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 0;
			border-bottom: 2px solid black;
			padding-top: 10px;
		}
	}
	.check-list__headline {
		color: #36cbc2;
		&:after {
			border-color: #36cbc2;
		}
	}
	.uncheck-list__headline {
		color: #e9386d;
		&:after {
			border-color: #e9386d;
		}
	}
	.special-list__ul {
		list-style-type: none;
		padding-bottom: 55px;
	}
	.check-list__li {
		background-image: url("/css/check2.svg");
	}
	.uncheck-list__li {
		background-image: url("/css/uncheck.svg");
	}
	.special-list__li {
		background-repeat: no-repeat;
		background-position: 0 4px;
		padding-left: 40px;
		margin-left: -40px;
		padding-bottom: 16px;
		color: ${color.main.text1};
		${typography.BodyXL};
	}
	.special-list__check {
		display: flex;
		flex-direction: column;
		padding-right: 80px;
		padding-bottom: 30px;
		border-bottom: 1px solid ${color.light.n500};
		padding-top: 100px;
		margin-top: 20px;
		border-top: 1px solid ${color.light.n500};
	}
	.special-list__uncheck {
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;
		border-bottom: 1px solid ${color.light.n500};
		padding-top: 100px;
	}
	.special-list__star {
		background-image: url("/css/star.svg");
		background-repeat: no-repeat;
		background-position: 0 0;
		padding-left: 12px;
		margin-left: -12px;
		padding-bottom: 40px;
	}

	@media (max-width: ${breakpoint.default.to}px) {
		flex-wrap: wrap;
		.special-list__check {
			width: 100%;
			padding-right: 0;
		}
		.special-list__uncheck {
			width: 100%;
		}
		.special-list__li {
			margin-left: 0px;
		}
		.special-list__ul {
			padding-left: 0 !important;
		}
	}
`

export default StyledListSpecial
